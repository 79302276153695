export const URLS = {
  userProfile: '/public/users/profile',
  logout: '/users/logout',
  countryList: '/countries',
  categoryList: '/systemclasses',
  loanTypesList: '/loantypes',
  businessUnitList: '/businessunits',
  contactMessage: '/users/mail',
  userList: '/users',
  createStock: '/stocks',
  rolesList: '/roles',
  createStockRole: '/stocks/user-roles',
  systemClass: '/businessunits/{id}/systemclasses',
  fetchIbase: '/iBase/{kitId}',
  stockWorkflow: '/stocks/{id}/workflows',
  stockDeliveryDays: '/stocks/{id}/delivery-days',
  stockMessages: '/stocks/{id}/messages',
  kit: '/kits',
  systemMainComponent: '/systemclasses/{id}/systemmaincomponents',
  kitStatus: '/kit-statuses',
  stockList: '/stocks/stock',
  kitsList: '/kits/list',
  uploadDocument: '/users/zeiss-documents',
  requestAccess: '/stocks/request-accesses',
  fetchRequestAccess: '/stocks/{stockId}/request-accesses',
  stockDetails: '/stocks/',
  uploadKitImage: '/kits/{kitId}/images',
  uploadKitAttachment: '/kits/{kitId}/attachments',
  kitDetailsMessages: '/kits/{kitId}/messages',
  fetchStockImage: '/stocks/{stockId}/images',
  getStockUserRoles: '/stocks/{id}/user-roles',
  deleteStock: '/stocks/',
  requestAccessList: '/stocks/{stockId}/request-accesses',
  requestStockAccessAceptOrReject: '/stocks/request-accept-reject',
  kitCondition: '/kit-conditions',
  deleteDocument: '/users/delete-zeiss-documents?deleteZeissDocumentId={id}',
  deleteKit: '/kits/',
  categoryAndSystem: 'categories/systemClasses',
  exportKitList: '/public/kit-list-excel',
  exportKitListFilter: '/kit-list-excel',
  copyDemoKit: '/demokits/',
  fetchNewKitsForHomepage: '/newKits',
  reasonForLoans: '/loanreasons',
  userAddress: '/users/{id}/addresses',
  getStocksForUser: '/stocks',
  kitLoans: '/kit-loans',
  kitReservation: '/kit-reservation',
  postNewAddress: '/users/addresses',
  singleLoans: '/single-loans',
  loanAttachments: '/loans/{transactionsId}/attachments',
  courseLoans: '/course-loans',
  courseLoanKits: '/course-loans/kits?stockId={stockId}&systemClassId={systemClassId}',
  transactions: '/transactions',
  transactionsList: '/transactions?operation={type}',
  transactionsListNew: '/transaction/search',
  transactionStatuses: '/transaction-statuses',
  transactionPositions: '/transaction-positions',
  cancelRequest: '/loans/{transactionId}/cancel-request',
  rejectCancelRequest: '/loans/{transactionId}/reject-cancel-request',
  updateTransactionTargetDate: (transactionsId, graphql) =>
    `/loans/${transactionsId}/target-dates?graphql=${graphql || ''}`,
  updateTransactionMilestones: (transactionsId, graphql) => `/loans/${transactionsId}/milestones?graphql=${graphql}`,
  updateReturnGoodsBooking: transactionsId => `/loans/${transactionsId}/return-goods-bookin`,
  updateTransactionStatus: (transactionsId, status) => `/loans/${transactionsId}/statuses?action=${status}`,
  createMessage: (transactionsId, type) => `/transaction/${transactionsId}/message/${type}`,
  transactionMessages: transactionsId => `/transactions/${transactionsId}/message`,
  transactionsC2CSuggest: transactionsId => `/transaction/${transactionsId}/c2c-suggest`,
  updateTransactionDetails: transactionsId => `/loans/${transactionsId}`,
  createC2CTransactions: '/transactions-to-c2c',
  clearC2CTransactions: '/transactions-clear-c2c',
  userMessages: '/user-messages',
  transactionChangeHistory: '/transactions-audit-log',
  downloadDocument: '/users/download-zeiss-document?zeissDocument={id}',
  downloadAttachment: '/kits/attachments?attachmentId={id}',
  saveCountry: '/countries',
  deliveryDocumentFooter: '/stock/{stockId}/footer-configuration',
  printDeliveryDocument: '/transaction/{id}/delivery-note',
  printReturnDeliveryDocument: '/transaction/{id}/return-delivery-note',
  deleteUserAddress: '/users/addresses/',
  transactionsSuggest: '/transaction-dates-check',
  exportTransactionList: '/transaction-list-excel',
  borrowedFrequentlyKits: '/user/current-user/kits?graphql={graphql}&limit={limit}',
  additionalLoanRequest: 'additional-loan-request?kitInformationIds=',
  returnGoodsBooking: transactionId => `/transactions/${transactionId}/return-goods-booking`,
  loanConsignment: transactionId => `/loans/${transactionId}/consignment`,
  loanTypes: '/loantypes',
  getStates: '/countries/{countryId}/states',
  systemClassWithStock: '/users-systemclasses',
  downloadLoanAttachment: '/loans/attachments?attachmentId={id}',
  getServiceTicketProblemList: '/kits/service-problems',
  ticket: '/api/ticket',
  transactionRatings: transactionId => `loans/${transactionId}/ratings`,
  serviceTicket: id => `/kits/${id}/service-ticket`,
  getListOfConnectedTransaction: transactionId => `loans/${transactionId}/bundle`,
  getKitStatistics: id => `/kits/${id}/statistics`,
  getTransactionDetails: transactionId => `/transaction/${transactionId}`,
  getTransactionDetailsWithLL: transactionId => `/transaction/${transactionId}/lab-location`,
  logError: 'logs/ui',
  team: '/team',
  teamMembers: '/team/members',
  calendarFilters: '/preferences/calendar',
  stockViewDuration: '/sell-off/stock-view-duration',
  updateSellOff: id => `/stocks/sell-off/${id}`,
  updateSellOffPost: id => `/stocks/${id}/sell-off`,
  sellOffCondition: '/sell-off/condition',
  sellOffVisibility: '/sell-off/visibility',
  sellOffCurrency: '/sell-off/currency',
  selloffplatform: '/kits/selloffplatform',
  selloffplatformAttachments: id => `/kits/selloffplatform/${id}/attachments`,
  sellStatus: id => `/stocks/${id}/sell-status`,
  fetchShop: '/kits/sell-off',
  kitManual: '/kits/kit-manual',
  getStockInfo: id => `/stocks/${id}`,
  kitSellOffListExport: '/kit-sell-off-list-excel',
  uploadKitImageForSale: id => `/kits/selloffplatform/${id}/images`,
  sellOffCountry: '/countries?loantype=shop-active',
  singleItem: '/kits/single-kit',
  subscribe: 'kit/reminder/subscribe',
  subscriptions: '/kit/current-user/subscriptions',
  unsubscribe: id => `kit/reminder/${id}/unsubscribe`,
  getAllQuestions: id => `/faq/questions/kit/${id}/all`,
  createQuestion: '/faq/questions',
  createAnswer: '/faq/answers',
  observe: '/kits/observe',
  observationList: '/kits/current-user/observations',
  deleteObservation: '/kits/observation',
  observationsListExport: '/kit-current-user-observations-list-excel',
  archive: '/kits/archive',
  archiveListExport: '/kit-sell-off-archive-list-excel',
  sellOffReserve: id => `/kits/${id}/sell-off/reserve`,
  reserved: '/kits/reserved',
  cancelReservation: id => `/kits/${id}/sell-off/cancel`,
  order: (id, crmQuoteId) => `/kits/${id}/sell-off/order?crmQuoteId=${crmQuoteId}`,
  sellOffStatistics: 'kits/sell-off/statistics',
  purchaseKit: '/purchase/kit',
  getPurchaseRequests: (page, size) => `/purchase/kit?page=${page}&size=${size}`,
  getPurchaseRequestById: id => `/purchase/kit/${id}`,
  purchaseComment: '/purchase/kit/comment',
  rmsSystemClass: '/users-systemclasses-rms',
  folder: '/folder',
  orderList: '/kits/order',
  orderListExport: '/kit-sell-off-order-list-excel',
  updatePurchaseRequest: '/purchase/kit',
  deletePurchaseRequest: id => `/purchase/kit/${id}`,
  loansAttachmentsDelete: '/loans/attachments/delete',
  exportKitGlobalDashboard: buName => `/statistic/kit-globaldashboard?buName=${buName}`,
  systemClassSubscribe: '/systemclass/subscribe',
  currentUserSubscription: '/system-class/current-user/subscriptions',
  deleteSystemClassSubscription: subscriptionId => `/system-class/subscriptions/${subscriptionId}`,
  demoUnitPermission: '/demo-unit/permission',
  demoUnitPermissionList: '/demo-unit/permission-list',
  demoUnitTask: '/demo-unit/task',
  demoUnutTaskList: '/demo-unit/tasks-list',
  demoUnitComment: '/demo-unit/comment',
  demoUnitTaskStatus: '/demo-unit/task/status',
  notificationSettings: '/users/notification/settings',
  subscribeNotifications: id => `/user/${id}/queue/notification`,
  notificationList: '/notification/list',
  notificationStatus: '/notification/status',
  notification: id => `/notification/${id}`,
  deleteAllNotifications: '/notification/all',
  productHierarchy: businessGroup => `/product-hierarchy?businessGroup=${businessGroup}`,
  commentAnswer: '/demo-unit/comment-answer',
  demoUnitCompletedList: id => `/demo-unit/completed-list/${id}`,
  demoUnitCompletedListWithDemoUnitStatus: (id, demoUnitStatus) =>
    `/demo-unit/completed-list/${id}?demoUnitStatus=${demoUnitStatus}`,
  crmOpportunities: '/crm/opportunities',
  exportTransactionGlobalDashboard: buName => `/statistic/transaction-globaldashboard?buName=${buName}`,
  userBusinessunits: '/user/businessunits',
  userCountries: '/user/countries',
  userStocks: '/user/stocks',
  userStocksByCountry: '/user/stocks/bu/countryIds',
  statisticOutgoingGoods: '/statistic/outgoing-goods',
  countriesWithActiveStocks: '/stocks/countries',
  stocksLoanAvailability: '/stocks/loan-availability',
  statisticIncomingGoods: '/statistic/incoming-goods',
  systemclassesBuName: buName => `/users-systemclasses?buName=${buName}`,
  statisticGlobalDashboard: '/statistic/global-dashboard',
  emailTypes: '/system-class/email/type/dropdown',
  statisticLoanReason: '/statistic/demo-activity/loan-reason',
  statisticDemoPerformed: '/statistic/demo-activity/demo-performed',
  statisticSatisfactionIndex: '/statistic/demo-activity/satisfaction-index',
  statisticDemosPerformedChart: '/statistic/demos-performed-chart',
  kitsSerialNumber: number => `/kits/serial-number?serialNumber=${number}&graphql=systemClassActive,kitInformationId`,
  statisticDelayTransactions: '/statistic/delay-transactions',
  statisticKitStatusChart: '/statistic/kit-status-chart',
  demoUnitDeleteComment: '/demo-unit/status',
  statisticKitDataDashboard: '/statistic/kit-data/dashboard',
  utilizedKits: '/statistic/kit-data/utilized-kits',
  capitalOverview: '/statistic/kit-data/capital-overview',
  interfaceMonitoring: '/interface-monitoring',
  normalUsers: '/normal-users',
  userPermissions: id => `/admin/user-permissions/${id}`,
  deleteAllUserPermissionsForStock: id => `/admin/stock/all/user-permission/${id}`,
  updateAllUserPermissions: (userId, roleId) => `/admin/stock/all/user-permission/${userId}/role/${roleId}`,
  updateUserPermission: '/admin/stock/all/user-permission',
  countriesByBusinessUnit: bu => `/businessunits/${bu}/countries`,
  userStockBusinessunits: '/user/stock/businessunits',
  kitsHome: '/kits/home',
  genericPictures: (buId, sysClassId, sysMainCompId) =>
    `/admin/kits/generic/pictures?buId=${buId}&sysClassId=${sysClassId}&sysMainCompId=${sysMainCompId}`,
  genericPicture: (buId, sysClassId, sysMainCompId) =>
    `/admin/kits/generic/picture?buId=${buId}&sysClassId=${sysClassId}&sysMainCompId=${sysMainCompId}`,
  deleteGenericPicture: id => `/admin/kits/generic/pictures/${id}`,
  labLocation: '/admin/rms/users/lablocation',
  activeUntilDate: id => `/stocks/${id}/active-until-date`,
  stocksBu: '/stocks/business-unit',
  llkitStatistic: id => `/kits/${id}/bookings-number`,
  labLocationСalendar: '/kits/lab-location/calendar',
  countriesLabsLocation: '/countries/lab-location',
  systemclassesLabsLocation: '/systemclasses/lab-location',
  loansTypesOfJobs: '/loans/zmcc/types-of-jobs',
  loansLabsLocationKitLoans: '/loans/labs-location/kit-loans',
  transactionsLabsLocationList: '/transaction/labs-location/search',
  loansLabsLocation: id => `/loans/labs-location/${id}`,
  userCountriesLabLocation: '/user/countries/lab-location',
  kitsTransactionsAttachments: kitId => `/kits/${kitId}/transactions/attachments`,
  compensationPoints: continent => `/compensation-points/list?continent=${continent}`,
  continents: '/compensation-points/continents',
  compensationPointsSetValue: '/compensation-points/set-value',
  labsLocationTargetDates: tranId => `loans/lab-location/${tranId}/target-dates`,
  transactionCompensationPoints: '/transaction/compensation-points/list',
  transactionCompensationPointsExel: '/transaction/compensation-point/list/excel',
  managerCountriesLabLocation: '/user/manager/countries/lab-location',
  demoUnitSystemClass: '/demo-unit/system-class',
  deductedPointsSetValue: '/deducted-points/set-value',
  statisticPartnerKpi: '/statistic/partner/kpi',
  statisticPartnerTypesOfJobs: '/statistic/partner/types-of-jobs',
  statisticPartnerUtilizedComponents: '/statistic/partner/utilized-components',
  getToken: '/token',
  getRefreshToken: '/refresh-token',
  stocksStatements: '/stocks/statements',
  exportControllingReport: '/stocks/controlling/report/excel',
  division: '/kits/divisions',
  checkErpInterfaceStatus: stockId => `stocks/${stockId}/erp-interface`,
  systemClassificationList: '/systemclasses/classification',
  addOrDeleteSystemClassification: '/systemclasses/classification',
  saveChangeReturnDeliverAdress: transactionId => `/loans/${transactionId}/return-delivery-address`,
  erpStatus: (transactionId, value) => `/loans/${transactionId}/enable-return-delivery-address?isEnable=${value}`,
  sapErpMappingList: '/sap-erp/address-mapping',
  sapErpMappingCreate: '/sap-erp/address-mapping/create',
  sapErpMappingEdit: '/sap-erp/address-mapping',
  sapErpMappingDelete: accountId => `/sap-erp/address-mapping/${accountId}`,
  orderType: '/loans/order-types',
  billings: '/loans/billings',
  distributionChannel: '/loans/distribution-channels',
  loanOrder: '/loans/sap-order',
  savingMaterialNumberAndDescription: transactionId => `loans/${transactionId}/material-numbers`,
  checkRefurbishmentStatusCheck: stockId => `stocks/${stockId}/workflow-loan-fee`,
  expectedLoanFees: 'loans/expected/fee',
  refurbishmentFessExport: '/transaction/loan-fee/excel',
  getRoles: stockId => `/stock/${stockId}/roles`,
  logisticsEmail: transactionId => `/loans/${transactionId}/logistics-email`,
  deliveryBlock: '/loans/delivery-block',
  sapErpMappingTable: '/sap-erp/mapping-table',
  sapErpCustomerAccounts: email => `/sap-erp/customer-accounts?email=${email}`,
  esbDeliveryNote: '/transaction/{id}/esb-delivery-note',
  createSapErpLoanOrderForKit: kitId => `/kits/${kitId}/sap-loan-order`,
  returnSapErpLoanOrderForKit: kitId => `/kits/${kitId}/sap-return-loan-order`,
  customerGroup: '/loans/customer-group',
  returnSapErpLoanOrderForTransaction: transactionId => `/loans/${transactionId}/return-loan-order`,
  activeLoanOrder: kitId => `/kits/${kitId}/active-loan-order`,
  manualReturnLoanOrder: '/loans/return-manual-loan-order',
  getMaintenanceChekcListDetailsByTransactionId: transactionId =>
    `/transaction/${transactionId}/maintenanceChecklistDetails`,
  updateMaintenanceChecklist: id => `${id}/maintenance-checkList`,
  medUsSerachCustomer: '/sap-erp/customer-accounts/bu-med-us-stocks',
  signalizeDemand: 'kits/signalize-demand',
  getDEmoRequestForm: '/kits/demo-request-form',
  kitViews: id => `/kits/${id}/view`,
  getUserIdFromBorrowerEmail: '/sap-crm/customer/user-id',
  getCustomerIDWithOpportunityID: '/sap-crm/customer-id/opportunity-id',
  addAddressConfigration: '/admin/activate/multiple-addresses',
  addressConfigrationList: '/admin/activate/multiple-addresses',
  enablingAddAddress: id => `/admin/activate/multiple-addresses/${id}`,
  deleteConfigration: id => `admin/activate/multiple-addresses/${id}`,
  stockAddressList: stockId => `/stocks/${stockId}/addresses`,
  addStockAddress: id => `stocks/${id}/addresses`,
  editStockAddress: id => `stocks/addresses/${id}`,
  deleteAddress: id => `/stocks/addresses/${id}`,
  showAddButton: id => `stocks/${id}/multiple-address-enabled`,
  getSearchedArress: `admin/med-us-addresses`,
  roomsList: warehouseId => `/stocks/${warehouseId}/rooms`,
  addNewRoom: warehouseId => `/stocks/${warehouseId}/rooms`,
  editRoom: roomId => `/stocks/rooms/${roomId}`,
  delteRoom: roomId => `/stocks/rooms/${roomId}`,
  advanceBooking: 'loans/lab-location/advance-booking',
  getUsersFromBU: businessunitId => `/users/${businessunitId}`,
  zmccCountryAndStocksList: `/user/countries/rms-zmcc`,
  exportLateReturnTransactionsList: '/late-returns-transaction-list-excel',
  zmccRequestSubmenu: '/loan-sub-menu',
  createSIngleLoanUsingKitLoanData: transactionId => `loans/${transactionId}/create-single-loan`,
  calendarBlocking: '/loans/blocking-check',
  getPreviousTranDetailsForPickupRequest: transactionId => `/transaction/${transactionId}/previous-transaction`,
  marketPlaceCountry: '/countries/marketplace',
  zmccCalendarSearch: 'kits/zmcc/calendar',
  zmccCreateSlotAvailaibility: 'loans/zmcc/calendar-block',
  zmccRemoveSlotAvailaibility: `loans/zmcc/calendar-block`,
  zmccGetUnexecutedSemTransaction: `loans/unexecuted-zmcc-transaction`,
  saveZmccSemTransaction: (actionValue, formType) => `/loans/zmcc?action=${actionValue}&formType=${formType}`,
  uploadZmccAttachmentAttachment: 'zmcc/loans/{transactionsId}/attachments',
  loansIntended: '/loans/intended',
  bestwayToReach: '/loans/bestway-to-reach',
  dataWithin: '/loans/data-within',
  marketSegments: '/loans/market-segments',
  realtionshipWithZeiss: '/loans/relationship-with-zeiss',
  statusOfSamples: '/loans/status-of-sample',
  dataFromCustomer: '/loans/data-from-customer',
  skillLevel: '/loans/skill-levels',
  cancelZmccSemTransaction : (transactionId) => `zmcc/loans/${transactionId}/cancel`,
  downloadZmccSemAttachment: '/zmcc/sem/attachments?attachmentId={id}',
  deleteKitReparation: (reparationId) => `/kits/reparation/${reparationId}`,
  getUnexecutedZmccTransactionById : (transactionId) => `/loans/zmcc/${transactionId}/form`,
  fetchZmccTransactionList: 'transaction/zmcc/search',
  zmccTransactionCategory: '/loans/category',
  estimateDays: '/loans/estimate-days',
  getWholeZmccTransactionDetails: (transactionId) => `/zmcc/sem/${transactionId}/getAllDetails`,
  rmsZmccUser: '/users/10',
  rmsZmccOperators: (stockId) => `/operators/${stockId}`,
  rmsZmccSubscribers: (stockId) => `/subscribers/${stockId}`,
  zmccPriorites: '/loans/zmcc/priorities',
  updateZmccTransaction: (transactionId, formType) => `/zmcc/${transactionId}/update?formType=${formType}`,
  zmccConnectedTransaction: warehouseId => `/zmcc/${warehouseId}/transactionNumbers`,
  zmccOperators: '/loans/sem/operators',
  getZmccShippingSampleAddress: (warehouseId) => `zmcc/${warehouseId}/shipping-address`,
  medUsWarehouseAddress: '/med/warehouse-addresses',
  editOrDeleteMedAddress: medWarehouseAddressId => `/med/warehouse-addresses/${medWarehouseAddressId}`,
  saveZmccAdjustDates: transactionId =>`/loans/${transactionId}/zmcc-suggested-date-time`,
  marketSubsegment: '/loans/zmcc/customer-market-subsgemnet',
  zmccZipDownload: '/download/folder/zip?folderName=ZMCC Documents',
  zmccTransactionStatues: (transactionId,statusValue) => `/zmcc/loans/${transactionId}/statuses?action=${statusValue}`,
  updateZmccTransactionDetailsInformationTab: (id,formType) => `zmcc/${id}/update/informationTab?formType=${formType}`,
  deleteAttendeAndTodos: id => `/loans/zmcc/attendees/${id}`,
  checkActiveTransaction: id => `/transactions/${id}/active-transaction`,
  saveCheckListForm: (id, type) => `/transaction/${id}/add-maintenance-checkList?action=${type}`,
  getfilteredKitByWarehouse: (warehouseId) => `/pending-slot-kits/warehouse/${warehouseId}`,
  createAvailability: (warehouseId) => `/warehouse/${warehouseId}/create-zmcc-slots`,
  getAvailabilityList: (warehouseId) => `/warehouse/slots/List/${warehouseId}`,
  editAvdailabilitySlot: (id) => `/warehouse/${id}/edit-zmcc-slot`,
  getAllKitsByWarehouse: (warehouseId) => `/all-kits/warehouse/${warehouseId}`,
  deleteAvailabiltySlot: (zmccWarehouseSlotId) => `/warehouse/${zmccWarehouseSlotId}/delete-zmcc-slot`,
  getAllScheduleList: accountId => `/zmcc/${accountId}/all-operator-schedule`,
  getScheludeType: '/types/zmcc-scheduled-types',
  addSchedule: '/zmcc/add-slot-to-operator-schedule',
  checIsOperatorUser: accountId => `/${accountId}/active-zmcc-operator`,
  getAdaptedLoanReasons: stockId => `/loanreasons/${stockId}`,
  systemCategories: '/types/zmcc-system-categories',
  getScheduledDetailes: scheduleId => `/zmcc/operator-schedule/${scheduleId}`,
  editMySchedule: scheduleId => `/zmcc/edit-operator-schedule/${scheduleId}`,
  deleteScheduledEvent: scheduleId => `/zmcc/delete-operator-schedule/${scheduleId}`,
  getOperatorSchedule: (date, stockId) => `/zmcc/all-operator-schedule/date-wise/`,
  assignOperatorsTpZmcc : (tranID) => `zmcc/${tranID}/assign/operators`,
  labLocationLocationTypesOfJobs: '/loans/lab-location/types-of-jobs',
  bulkCancelTwentyFourHoursResevation: '/cancel-bulk-reservations',
  typeOfJobAssociatedwithKitsSystemMainComponent: kitId => `/types-of-job/kit-system-main-component-system-classification/${kitId}`,
  operatorsForZmccCalendar: 'kits/zmcc/calender-operators',
  zmccLogisticsEmail: transactionId => `/zmcc/${transactionId}/send-email`,
  createZmccBooking: '/loans/zmcc-calendar/',
  exportKitGlobalDashboardWithCountry: (buName,countryName) => `/statistic/kit-globaldashboard-with-country?buName=${buName}&country=${countryName}`,
  exportTransactionsGlobalDashboardWithCountry: (buName,countryName) => `/statistic/transaction-globaldashboard-with-country?buName=${buName}&country=${countryName}`,
  exportStockSystemClass:'/export-stocks-systemclasses',
  loanTypesListForHomePage: '/loantypes?fromHomePage=true',
  getUserCheckedDontShowAgain: '/dont-show-popup-again',
  safeSearchList: '/api/safe-search/search',
  saveQuery: 'api/safe-search/',
  editQuery:(id) =>  `api/safe-search/${id}`,
  deleteMyQuery: (id) => `api/safe-search/${id}`,
  sbuTypes: '/loans/sbu_types',
  newRegistrations: '/users/new-registrations',
  dmaReporting: '/dma-usage-reports',
  sixMonthstraffic: '/dma-usage/traffic-last-6-months',
  exportSystemClassStock: '/export-stockids-systemclasses',
  operatorScheduleDetails: (transactionId) => `/zmcc/${transactionId}/operator-schedule-details`,
  stockProvincesDeliveryDays: '/stocks/{id}/delivery-days-of-provinces',
  getStatesSpecialDeliveryTime: (stockId,countryId,stateId) => `/stocks/${stockId}/${countryId}/${stateId}/delivery-days`,
  getAllActiveProvinceCountries: `get-active-province-countries`,
  getStocksBasedOnCountries: '/countries-stocks',

};
