import Axios from 'axios';

export const exportListRequest = (url, valuesToSend, token) => {
  return Axios(process.env.REACT_APP_BASE_URL + url, {
    method: 'POST',
    responseType: 'blob',
    headers: {
      token: `${token}`,
      'Content-Type': 'application/json',
      'Accept-Language': 'en',
    },
    data: valuesToSend,
  });
}
