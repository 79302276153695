import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { checkHasMedUsWarehouseAccess, checkUserRoles } from 'library/utilities/checkRoles';
import { permissions } from 'library/common/commonConstants/permissionsConstants';
import strings from 'resources/locales/Translate';

const Navigation = ({ user, pathname, location, refurbishmentFessStockList, loansSubMenu }) => {
  const isAdmin = user && user.admin;
  const hasOnlyDustUserRole = user && user.dustUser;
  const isUserPartner = user && user.labLocationPartner;
  const checkActiveClassForLoan = pathname === '/single-loan' || pathname === '/course-loan' || pathname === '/SEM';
  const checkActiveClassForBooking = pathname === '/my-bookings';
  const checkActiveClassForShop = pathname.includes('/demo-sale');
  const checkActiveClassForDUSD = pathname.includes('/dusd');
  const checkActiveClassForAdministration = pathname.includes('/administration');
  const isShowBookingTeams = checkUserRoles(permissions.bookingTeams, user);
  const isShowOrderList = checkUserRoles(permissions.orderList, user);
  const isShowAdministrationMenu = checkUserRoles(permissions.stockDashboardMenu, user);
  const isShowDustTaskAndPermissions = checkUserRoles(permissions.permissionsMenu, user);
  const isShowSubscriptions = checkUserRoles(permissions.subscriptionsMenu, user);
  const hasMaintainStatusPermissions = checkUserRoles(permissions.maintainStatusMenu, user);
  const isShowUserPermissionsMenu = checkUserRoles(permissions.userPermissionsMenu, user);
  const hasActiveSystemsPermissions = checkUserRoles(permissions.activeSystemsMenu, user);
  const isDustUser = checkUserRoles(permissions.dustStatus, user);
  const hasMedUsWarehouseAccess = checkHasMedUsWarehouseAccess(user);
  const rmsUser =
    user &&
    user.stockRoles &&
    user.stockRoles.filter(role => role.businessUnitName === 'RMS' && role.roleName !== 'Shop Manager').length;
  const llRMSUser =
    user &&
    user.stockRoles &&
    user.stockRoles.filter(
      role => role.businessUnitName === 'RMS labs@location Partner' && role.roleName !== 'Shop Manager',
    ).length;
  const allUserRoles = user && user.stockRoles && user.stockRoles.filter(role => role.businessUnitName).length;
  const noUserRoles = !isAdmin && !isUserPartner && allUserRoles === 0;
  const userHasOnlyLLRMS = llRMSUser === allUserRoles && !isAdmin;
  const isShowLabLocationMenu = isAdmin || isUserPartner || !!rmsUser || !!llRMSUser;
  const isLLManager =
    user &&
    user.stockRoles.find(item => item.businessUnitName === 'RMS labs@location Partner' && (item.roleName === 'Dispatcher'));
  const canViewSapErpMapping =
    user && (user.admin || user.stockRoles.some(item => item.roleName === 'Stock Controller'));
  const isLLEnabled = process.env.REACT_APP_IS_LABSLOCATION_ENABLED === 'true';
  const checkStockId = () => {
    if (user && user.admin) {
      return true;
    }
    let stockRoles = user && user.stockRoles;
    let myStockIds = [];
    let refurbishmentId = [];
    if (stockRoles && stockRoles.length) {
      stockRoles.forEach(stockRole => {
        if (stockRole.businessUnitName !== 'RMS labs@location Partner' && stockRole.roleName === 'Stock Controller') {
          myStockIds.push(stockRole.stockId);
        }
      });
    }
    const removingNullStockInformation =
      refurbishmentFessStockList &&
      refurbishmentFessStockList.length &&
      refurbishmentFessStockList.filter(item => item.stockInformation && item.stockInformation.length > 0);
    const stockInformation =
      removingNullStockInformation &&
      removingNullStockInformation.length &&
      removingNullStockInformation.map(item => item.stockInformation.map(item => refurbishmentId.push(item.id)));

    if (myStockIds.length > 0) {
      for (let index = 0; index < myStockIds.length; index++) {
        if (refurbishmentId.length > 0) {
          if (refurbishmentId.includes(myStockIds[index])) {
            return true;
          }
        }
      }
    }

    return false;
  };
  const showtab = checkStockId();
  const isZmccUser =
    user && user.stockRoles.find(item => item.businessUnitName === 'RMS (ZMCC)');
  const isZmccEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';

  const limitMenuOptionsForZmccRequestor = () => {
    let value;
    const businessUnits = ['RMS', 'MED-SUR', 'MED-OPT', 'COP', 'VIS', 'IQS', 'SMT', 'MED', 'RMS labs@location Partner'];
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      let isDlaOrLabsLocation = false;
      let isZmcc = false;
      let isZmccBorrower = false;
      for (let i = 0; i < user.stockRoles.length; i++) {
        const element = user.stockRoles[i];
        if (businessUnits.includes(element.businessUnitName)) {
          value = true;
          isDlaOrLabsLocation = true;
        } else if (element.businessUnitName === 'RMS (ZMCC)' && element.roleName !== 'Borrower') {
          value = true;
          isZmcc = true;
        } else if (element.businessUnitName === 'RMS (ZMCC)' && element.roleName === 'Borrower') {
          value = false;
          isZmccBorrower = true;
        }
      }
      value = (isDlaOrLabsLocation || isZmcc);
    }
    return value;
  }

  const isZmccRequestor = limitMenuOptionsForZmccRequestor();
  const hideTransactionForZmccUser = user && user.stockRoles.find(item => item.businessUnitName !== 'RMS (ZMCC)');

  if (hasOnlyDustUserRole) {
    return (
      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav mr-auto'>
          <li className='nav-item'>
            <NavLink to={'/home'} exact={true} activeClassName='active' className='nav-link'>
              {strings.homepage}
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to={'/stocks'} exact={true} activeClassName='active' className='nav-link'>
              {strings.stocks}
            </NavLink>
          </li>
          <li className='nav-item loan-menu dropdown'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForDUSD })}
              id='dusdDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span data-toggle='dropdown' className='dropdown-toggle'>
                {strings.demoUnitStatusTool}
              </span>
              <div className='dropdown-menu rounded-0' aria-labelledby='administartionDropdown'>
                <NavLink exact={true} to={'/dusd/subscriptions'} className='dropdown-item'>
                  {strings.subscriptions}
                </NavLink>
                <NavLink exact={true} to={'/dusd/maintain-status'} className='dropdown-item'>
                  {strings.maintainStatus}
                </NavLink>
                <NavLink exact={true} to={'/dusd/dusd-task'} className='dropdown-item'>
                  {strings.tasks}
                </NavLink>
                <NavLink exact={true} to={'/dust/active-systems'} className='dropdown-item'>
                  {strings.activeSystems}
                </NavLink>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
      <ul className='navbar-nav mr-auto'>
        <li className='nav-item'>
          <NavLink to={'/home'} exact={true} activeClassName='active' className='nav-link'>
            {strings.homepage}
          </NavLink>
        </li>
        <li className='nav-item loan-menu dropdown'>
          <div
            className={cn('nav-link cursor-pointer', { active: checkActiveClassForLoan })}
            id='loanDropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span data-toggle='dropdown' className='dropdown-toggle'>
              {strings.homeBooking}
            </span>
            <div className='dropdown-menu rounded-0' aria-labelledby='loanDropdown'>
              {(!userHasOnlyLLRMS || noUserRoles || !isUserPartner) && (
                <NavLink to={'/calendar'} exact={true} className='dropdown-item'>
                  {strings.kitLoan}
                </NavLink>
              )}
              {!isUserPartner && (
                <NavLink to={'/course-loan'} exact={true} className='dropdown-item'>
                  {strings.courseLoan}
                </NavLink>
              )}
              {!isUserPartner && (
                <NavLink to={'/single-loan'} exact={true} className='dropdown-item'>
                  {strings.singleLoan}
                </NavLink>
              )}
              {isShowLabLocationMenu && (
                <NavLink
                  to={{ pathname: '/labs-location-calendar', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {strings.labsLocationRequest}
                </NavLink>
              )}
               {isZmccEnabled && isZmccRequestor && <NavLink
                  to={{ pathname: '/zmcc-calendar', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {strings.zmccCalendar}
                </NavLink>}
              {isZmccUser && isZmccEnabled && (
                <li>
                  <>
                    <NavLink to={{ pathname: '/', prevLocation: location }} exact={true} className='dropdown-item'>
                      {strings.zmccRequest}
                    </NavLink>
                    <ul class='dropdown-menu dropdown-submenu'>
                      {loansSubMenu &&
                        loansSubMenu.length > 0 &&
                        loansSubMenu.map(item => (
                          <li>
                            <NavLink
                              to={{ pathname: `/${item.translationSubMenu}`, prevLocation: location }}
                              exact={true}
                              className='dropdown-item'
                            >
                              {item.translationSubMenu}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </>
                </li>
              )}
            </div>
          </div>
        </li>
        <li className='nav-item loan-menu dropdown'>
          <div
            className={cn('nav-link cursor-pointer', { active: checkActiveClassForBooking })}
            id='bookingDropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span data-toggle='dropdown' className='dropdown-toggle'>
              {strings.transaction}
            </span>
            <div className='dropdown-menu rounded-0' aria-labelledby='bookingDropdown'>
            {(user.admin || isZmccUser )&& <NavLink
                to={{ pathname: '/my-queries', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.myQueries}
              </NavLink>}

              {isShowBookingTeams && !isUserPartner && (
                <NavLink
                  to={{ pathname: '/team-bookings', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {strings.teamBookings}
                </NavLink>
              )}

              <NavLink to={{ pathname: '/my-bookings', prevLocation: location }} exact={true} className='dropdown-item'>
                {strings.myBookings}
              </NavLink>

              <NavLink
                to={{ pathname: '/open-processes', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.openProcesses}
              </NavLink>
              <NavLink
                to={{ pathname: '/loan-requests', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.loanRequests}
              </NavLink>
              {hideTransactionForZmccUser && !isUserPartner && (
                <>
                  <NavLink
                    to={{ pathname: '/sap-order-creation', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.sapOrderCreation}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/outgoing-goods', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.outgoingGoods}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/list-receiving', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.listReceiving}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/incoming-goods', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.incomingDemoKits}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/good-receipt', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.goodReceipt}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/list-reconditioning', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.listReconditioning}
                  </NavLink>
                </>
              )}
              <NavLink
                to={{ pathname: '/cancellation-approval', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.cancellationApproval}
              </NavLink>
              <NavLink
                to={{ pathname: '/cancelled-processes', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.cancelledProcesses}
              </NavLink>
              <NavLink
                to={{ pathname: '/archive-processes', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.archiveProcesses}
              </NavLink>
              {hideTransactionForZmccUser && !isUserPartner && (
                <NavLink to={{ pathname: '/c2c-loans', prevLocation: location }} exact={true} className='dropdown-item'>
                  {strings.c2cLoans}
                </NavLink>
              )}
            </div>
          </div>
        </li>
        {isZmccRequestor && <li className='nav-item'>
          <NavLink
            to={{ pathname: '/kits', prevLocation: location }}
            exact={true}
            activeClassName='active'
            className='nav-link'
          >
            {strings.kits}
          </NavLink>
        </li>}
        {isZmccRequestor &&
          <li className='nav-item'>
          <NavLink to={'/stocks'} exact={true} activeClassName='active' className='nav-link'>
            {strings.stocks}
          </NavLink>
        </li>}
        {(!isUserPartner) && (
          <li className='nav-item loan-menu dropdown'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForShop })}
              id='shopDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              {isZmccRequestor && <span data-toggle='dropdown' className='dropdown-toggle'>
                {strings.demoSale}
              </span>}
              <div className='dropdown-menu rounded-0' aria-labelledby='shopDropdown'>
                <NavLink to={'/demo-sale'} exact={true} className='dropdown-item'>
                  {strings.salesOffers}
                </NavLink>
                <NavLink to={'/demo-sale/observations'} exact={true} className='dropdown-item'>
                  {strings.myObservations}
                </NavLink>
                <NavLink to={'/demo-sale/reservation'} exact={true} className='dropdown-item'>
                  {strings.reservation}
                </NavLink>
                {isShowOrderList && (
                  <NavLink to={'/demo-sale/order'} exact={true} className='dropdown-item'>
                    {strings.orders}
                  </NavLink>
                )}
                <NavLink to={'/demo-sale/archive'} exact={true} className='dropdown-item'>
                  {strings.archive}
                </NavLink>
                <NavLink to={'/demo-sale/statistics'} exact={true} className='dropdown-item'>
                  {strings.statistics}
                </NavLink>
                <NavLink to={'/demo-sale/purchase-requests'} exact={true} className='dropdown-item'>
                  {strings.purchaseRequests}
                </NavLink>
              </div>
            </div>
          </li>
        )}
        {isShowSubscriptions && !isUserPartner && (
          <li className='nav-item loan-menu dropdown'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForDUSD })}
              id='dusdDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span data-toggle='dropdown' className='dropdown-toggle'>
                {strings.demoUnitStatusTool}
              </span>
              <div className='dropdown-menu rounded-0' aria-labelledby='administartionDropdown'>
                {isShowDustTaskAndPermissions && (
                  <NavLink to={'/dusd/permissions'} exact={true} className='dropdown-item'>
                    {strings.permissions}
                  </NavLink>
                )}
                {isDustUser && (
                  <NavLink exact={true} to={'/dusd/dusd-task'} className='dropdown-item'>
                    {strings.tasks}
                  </NavLink>
                )}
                {isShowSubscriptions && (
                  <NavLink exact={true} to={'/dusd/subscriptions'} className='dropdown-item'>
                    {strings.subscriptions}
                  </NavLink>
                )}
                {hasMaintainStatusPermissions && (
                  <NavLink exact={true} to={'/dusd/maintain-status'} className='dropdown-item'>
                    {strings.maintainStatus}
                  </NavLink>
                )}
                {hasActiveSystemsPermissions && (
                  <NavLink exact={true} to={'/dust/active-systems'} className='dropdown-item'>
                    {strings.activeSystems}
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}
        {isShowAdministrationMenu && (
          <li className='nav-item loan-menu dropdown'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForAdministration })}
              id='administartionDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span data-toggle='dropdown' className='dropdown-toggle'>
                {strings.administration}
              </span>
              <div className='dropdown-menu rounded-0' aria-labelledby='administartionDropdown'>
                {(isAdmin || hasMedUsWarehouseAccess) && (
                  <NavLink to={'/administration/master-data'} exact={true} className='dropdown-item'>
                    {strings.masterData}
                  </NavLink>
                )}
                {isShowBookingTeams && !isUserPartner &&(
                  <NavLink to={'/administration/export-data'} exact={true} className='dropdown-item'>
                    {strings.exportData}
                  </NavLink>
                )}
                {isShowAdministrationMenu && !isUserPartner && (
                  <NavLink to={'/administration/stock-dashboard'} exact={true} className='dropdown-item'>
                    {strings.stockDashboard}
                  </NavLink>
                )}
                {isShowAdministrationMenu && !isUserPartner && (
                  <NavLink to={'/administration/statistics'} exact={true} className='dropdown-item'>
                    {strings.statistics}
                  </NavLink>
                )}
                {isAdmin && (
                  <NavLink to={'/administration/interface-monitoring'} exact={true} className='dropdown-item'>
                    {strings.interfaceMonitoring}
                  </NavLink>
                )}
                {isShowUserPermissionsMenu && !isUserPartner && (
                  <NavLink to={'/administration/user-permissions'} exact={true} className='dropdown-item'>
                    {strings.userPermissions}
                  </NavLink>
                )}
                {isAdmin && (
                  <NavLink to={'/administration/kit-pictures'} exact={true} className='dropdown-item'>
                    {strings.manageKitPictures}
                  </NavLink>
                )}
                {isAdmin && isLLEnabled && (
                  <NavLink to={'/administration/partner-permissions'} exact={true} className='dropdown-item'>
                    {strings.partnerPermissions}
                  </NavLink>
                )}
                {(isAdmin || isLLManager || isUserPartner) && isLLEnabled && (
                  <NavLink to={'/administration/labs-location-management'} exact={true} className='dropdown-item'>
                    {strings.labsLocationManagement}
                  </NavLink>
                )}
                {canViewSapErpMapping && !isUserPartner && (
                  <NavLink to={'/administration/sap-erp-mapping'} exact={true} className='dropdown-item'>
                    {strings.sapERPMappingTable}
                  </NavLink>
                )}
                {showtab && (
                  <NavLink to={'/administration/refurbishment-fees'} exact={true} className='dropdown-item'>
                    {strings.refurbishmentFees}
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navigation;
