export const footerModel = {
  navigation: [
    {
      label: 'homepage',
      toPath: '/home'
    }, {
      label: 'calendar',
      toPath: '/calendar'
    }, {
      label: 'loan',
      toPath: '/Loan'
    }, {
      label: 'transaction',
      toPath: '/my-bookings'
    }, {
      label: 'kits',
      toPath: '/kits'
    }, {
      label: 'stocks',
      toPath: '/stocks'
    }
  ],
  address: [
    {
      label: 'Carl Zeiss Microscopy GmbH'
    }, {
      label: 'Carl-Zeiss-Promenade 10'
    }, {
      label: '07745 Jena'
    }, {
      label: 'Germany'
    }
  ],
  contact: [
    {
      iconKey: 'message',
      label: 'demoloanapp@zeiss.com'
    },
  ],
  socialMedia: [
    {
      iconKey: 'facebook',
      label: 'Facebook',
      link: 'https://www.facebook.com/zeissgroup/'
    }, {
      iconKey: 'twitter',
      label: 'X',
      link: 'https://twitter.com/zeiss_group'
    }, {
      iconKey: 'youtube',
      label: 'Youtube',
      link: 'https://www.youtube.com/ZEISSGroup'
    },
  ],
};

export const footerLinks = {
  imprints: {
    en: 'https://www.micro-shop.zeiss.com/en/de/shop/imprint',
    de: 'https://www.micro-shop.zeiss.com/de/de/shop/imprint'
  },
  legalNotice: {
    en: 'https://www.zeiss.com/corporate/int/legal-information/legal-notice-and-general-terms-and-conditions.html',
    de: 'https://www.zeiss.de/corporate/rechtliches/rechtshinweise.html?vaURL=www.zeiss.de/rechtshinweise'
  },
  dataProtection: {
    en: 'https://www.zeiss.com/corporate/int/legal-information/data-protection.html',
    de: 'https://www.zeiss.de/corporate/rechtliches/datenschutz.html?vaURL=www.zeiss.de/datenschutz'
  },
  carlZeiss: 'https://www.zeiss.de/mikroskopie/home.html?vaURL=www.zeiss.de/mikro',
  carlZeissInternational: 'https://www.zeiss.com/microscopy/int/home.html'
};
