import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {
  fetchStockDetails,
  saveStockInformation,
  updateStockInformation,
  cancelStockCreation,
  getStateInput,
  fetchStockAddress,
  addNewAddress,
  editAddress,
  deleteAddress,
  updateCrmAccountId,
  getAllActiveProvinceCountries
} from 'modules/Stock/CreateStock/CreateStock.actions';

import Information from './Information.component';

const mapStateToProps = ({ createStockReducer, languageReducer, masterDataReducer }) => ({
  masterData: masterDataReducer,
  createStockReducer: createStockReducer,
  entitlements: createStockReducer.entitlements,
  language: languageReducer,
});

export default connect(mapStateToProps, {
  saveStockInformation,
  toggleActionMessage,
  fetchStockDetails,
  updateStockInformation,
  cancelStockCreation,
  getStateInput,
  fetchStockAddress,
  addNewAddress,
  editAddress,
  deleteAddress,
  updateCrmAccountId,
  getAllActiveProvinceCountries
})(Information);
