import Validators from 'library/utilities/Validators';

export const exportModel = ({ categoriesAndSystems, countries, singleItemsOptions, marketplaceCountry }) => [
  {
    label: 'country',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'country',
    validators: [],
    required: false,
    styleClass: 'col-12',
    options: marketplaceCountry,
    hasSection: false,
    multiSelect: true,
    filter: false,
    selectAllOption: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'stocks',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stock',
    validators: [{ check: Validators.maxLength, message: 'youCanChooseOnly1Stock', length: 1 }],
    required: false,
    styleClass: 'col-12',
    options: countries,
    hasSection: true,
    multiSelect: true,
    filter: true,
    selectAllOption: false,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'categorySystem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'category',
    validators: [],
    required: false,
    styleClass: 'col-12',
    options: categoriesAndSystems,
    hasSection: true,
    multiSelect: true,
    filter: true,
    selectAllOption: true,
    idKey: 'systemMainComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
    optionsArrayKey: 'systemMainComponent',
    titleDisplay: [{ key: 'systemClassName', separator: '' }],
  },
  {
    label: 'singleItemsKit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'singleItems',
    validators: [],
    required: false,
    styleClass: 'col-12',
    options: singleItemsOptions,
    hasSection: false,
    multiSelect: false,
    filter: false,
    selectAllOption: true,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  },
];
