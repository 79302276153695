import React from 'react';

import PieChart from 'library/common/commonComponents/PieChart';
import strings from 'resources/locales/Translate';

import DemoActivityAndKitDataFilter from '../DemoActivityAndKitDataFilter';
import DemosStatisticBlock from '../DemosStatisticBlock';
import SatisfactionIndexChart from '../SatisfactionIndexChart';
import DemosBlock from '../DemosBlock';
import { useDemoActivityTab } from './DemoActiveTab.hook';

const DemoActivityTab = ({
  isShowFilter,
  loanTypes,
  stocksDemoActivity,
  systemClassDemoActivity,
  loanReasonsStatistic,
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  getLoanReasonStatistic,
  clearStatistics,
  language,
  getDemosStatistic,
  demosStatistic,
  satisfactionIndexStatistic,
  getSatisfactionIndexStatistics,
  getDemosChartData,
}) => {
  const {
    filters,
    hasDataForChart,
    data,
    setFilters,
    systemClass,
  } = useDemoActivityTab({
    systemClassDemoActivity,
    loanReasonsStatistic,
    getStocksByBussinessUnitandCountryIds,
    getSystemClassesByBU,
    getLoanReasonStatistic,
    clearStatistics,
    language,
    getDemosStatistic,
    getSatisfactionIndexStatistics,
    getDemosChartData,
  });
  const {
    onTimeDeliveryRate,
    orderCompleteness,
    equipmentFunctionality,
    totalRatedTransactions
  } = satisfactionIndexStatistic || {};

  return (
    <>
      <DemoActivityAndKitDataFilter
        isShowFilter={isShowFilter}
        loanTypes={loanTypes}
        stocks={stocksDemoActivity}
        systemClass={systemClass}
        filters={filters}
        setFilters={setFilters}
      />
      <div className='container-fluid tab-wrapper'>
        <h2>{strings.loanReasons}</h2>
        {hasDataForChart ? (
          <div className='chart-wrapper'>
            <PieChart data={data} />
          </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        <DemosStatisticBlock demosStatistic={demosStatistic} />
        <DemosBlock />
        <h2 className='mb-3'>{strings.demoSatisfactionIndex(totalRatedTransactions?totalRatedTransactions:0)}</h2>
        <div className='d-flex'>
          <SatisfactionIndexChart satisfactionIndex={onTimeDeliveryRate} legend={strings.onTimeDeliveryRate}  />
          <SatisfactionIndexChart satisfactionIndex={orderCompleteness} legend={strings.orderCompleteness} />
          <SatisfactionIndexChart satisfactionIndex={equipmentFunctionality} legend={strings.equipmentFunctionality} />
        </div>
      </div>
    </>
  );
}

export default DemoActivityTab;
