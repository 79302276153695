import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
require('dayjs/locale/en');
require('dayjs/locale/de');

export const getDateInFormat = (format, dateFrom, dateTo, locale = 'en') => {
  if (dateTo) return `${dayjs(dateFrom).locale(locale).format(format)} - ${dayjs(dateTo).locale(locale).format(format)}`

  return dayjs(dateFrom).locale(locale).format(format);
}

export const getDateInUTCFormat = (format, dateFrom, dateTo) => {
  if (dateTo) return `${dayjs.utc(dateFrom).format(format)} - ${dayjs.utc(dateTo).format(format)}`

  return dayjs.utc(dateFrom).format(format);
}
