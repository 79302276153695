import React from 'react';
import cn from 'classnames';

import './stylesheet.scss';

export const Checkbox = ({ className, dataTest, disabled, field, label, onChange, selected, style, small }) => {
  const handleChange = event => {
    const { checked } = event.target;
    onChange(checked, field);
  };

  return (
    <label className={`checkbox-container ${className ? className : ''}`} data-test={dataTest} style={style}>
      <p className={'label'}>{label}</p>
      <input type='checkbox' checked={!!selected} onChange={handleChange} disabled={disabled} />
      <span className={cn({ checkmark: !small, 'small-checkmark': small })}> </span>
    </label>
  );
};

Checkbox.defaultProps = {
  label: '',
  selected: false,
  field: '',
  disabled: false,
  style: {},
  onClick: () => {},
};

export default Checkbox;
