import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';

import { itemsPerPage  } from '../../Statistics.constants';
import LateReturnsExpandableTable from '../LateReturnsExpandableTable';
import DelayedTransactionsFilter from '../DelayedTransactionsFilter';
import { useDelayedTransactionsTab } from './DelayedTransactionsTab.hook';
import Button from 'library/common/commonComponents/Button/Button';
import { scrollToTop } from 'library/utilities/scrollActions';

const DelayedTransactionsTab = ({
  isShowFilter,
  loanTypes,
  stocksDelayedTransaction,
  lateTransactionsList,
  getStocksByBussinessUnitandCountryIds,
  getLateReturnsTransactions,
  clearLateReturnsTransactions,
  user,
  exportLateReturnsTransactionList,
  toggleActionMessage,
  language,
}) => {
  const {
    filters,
    data,
    cols,
    isShowActiveLateTransactions,
    setFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
  } = useDelayedTransactionsTab({
    getStocksByBussinessUnitandCountryIds,
    getLateReturnsTransactions,
    lateTransactionsList,
    clearLateReturnsTransactions,
  });

  const checkExportLateReturnListPermission =
  user && 
  user.stockRoles.find(
    item => item.roleName.toLowerCase() === 'stock controller' || item.roleName.toLowerCase() === 'manager' || item.roleName.toLowerCase() === 'dispatcher',
  );

  const downloadLateReturnsTransList = () =>{
    if ((filters.country == null || filters.country.length === 0)){
      scrollToTop(500);
      toggleActionMessage(true, 'error', strings.lateReturnExportError, true);
    }
    else {
      exportLateReturnsTransactionList(filters,data,lateTransactionsList.totalElements,language);
    }
  }
  return (
    <>
      <DelayedTransactionsFilter
        isShowFilter={isShowFilter}
        loanTypes={loanTypes}
        stocks={stocksDelayedTransaction}
        filters={filters}
        setFilters={setFilters}
      />
      <div className='container-fluid tab-wrapper'>
        <h2 className='mb-3'>{strings.allActiveLateTransactions}</h2>
        {isShowActiveLateTransactions ? (
          <ExpandableTable
            cols={cols}
            rows={lateTransactionsList.content}
            sortFields={data.sort}
            handleSort={handleSort}
          >
            <LateReturnsExpandableTable />
          </ExpandableTable>
        ) : (
          <div className='d-flex justify-content-center mb-3'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        {isShowActiveLateTransactions && (
          <div className='col d-flex justify-content-end mb-3'>
            <ItemsPerPage
              itemsPerPage={toArray(itemsPerPage)}
              handleItemsPerPage={handleItemsPerPage}
              value={itemsPerPage[data.size]}
            />
            <Pagination
              currentPage={data.page - 1}
              totalPages={lateTransactionsList.totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        <div className='row no-gutters'>
          {isShowActiveLateTransactions && (checkExportLateReturnListPermission || (user && user.admin)) && (
            <div className='col-md-auto mb-3'>
              <Button
                bgFill={false}
                iconName='download'
                value={strings.exportCurrentList}
                styleClass='mt-0'
                onClick={() => downloadLateReturnsTransList()}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DelayedTransactionsTab;
