import React from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Icon from 'library/common/commonComponents/Icon/Icon';

import { singleItemsOptions } from '../../Shop.constants';

export default function Filter({
  isShowFilter,
  countries,
  categoriesAndSystems,
  resetFilters,
  handleFilterChange,
  selected,
}) {

  const handleFilter = key => (value, field, fullValue) => {
    handleFilterChange(key, value, fullValue);
  };

  const getPlaceholder = (selectedValues, displayValue, multiple) => {
    let displayPlaceholder = '';
    if (!multiple && selectedValues) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedValues[display.key] + display.separator + selectedValues[display.name];
          }
          return selectedValues[display.key] + display.separator;
        })
        .join('');
    }
    if (selectedValues && selectedValues.length > 0) {
      const displayFormat = selectedValues.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });
      displayPlaceholder =
        selectedValues.length === 1 ? displayFormat[0] : `${displayFormat[0]} + ${displayFormat.length - 1}`;
    }
    return displayPlaceholder;
  };

  const resetFilter = key => () => {
    handleFilterChange(key, null, null);
  };

  const renderFilters = () => {
    const newFilters = [];
    const filters = {
      country: { displayValue: [{ key: 'name', separator: '' }], multiple: false },
      category: {
        displayValue: [{ key: 'systemMainComponentName', separator: '', optionsArrayKey: 'systemMainComponent' }],
        multiple: true,
      },
      singleItems: { displayValue: [{ key: 'value', separator: '' }], multiple: false },
    };
    Object.keys(filters).forEach(key => {
      if (
        selected &&
        selected[key] &&
        (typeof selected[key] !== 'object' || selected[key].length) && selected[`${key}FullValue`]
      ) {
        newFilters.push(
          <div className='filter-chips-container' key={key}>
            {getPlaceholder(
              selected[`${key}FullValue`],
              filters[key].displayValue,
              filters[key].multiple,
            )}
            <span onClick={resetFilter(key)}>&times;</span>
          </div>,
        );
      }
    });
    return newFilters;
  };

  const filters = renderFilters();

  return (
    <>
      <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className='container-fluid'>
          <div className='row'>
            <Dropdown
              data={countries}
              filter
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              idKey='id'
              field='country'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.countryLand}
              onChange={handleFilter('country')}
              mainContainerStyle='col-lg-4'
              value={selected.country}
              fullValue={selected.countryFullValue}
            />
            <Dropdown
              data={categoriesAndSystems}
              hasSection
              multiSelect
              filter
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              field='category'
              idKey='systemMainComponentId'
              displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
              optionsArrayKey='systemMainComponent'
              titleDisplay={[{ key: 'systemClassName', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.categorySystem}
              onChange={handleFilter('category')}
              mainContainerStyle='col-lg-4 mt-3 mt-md-0'
              value={selected.category}
              fullValue={selected.categoryFullValue}
            />
            <Dropdown
              data={singleItemsOptions()}
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              idKey='id'
              field='singleItems'
              displayValue={[{ key: 'value', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.singleItemsKit}
              onChange={handleFilter('singleItems')}
              mainContainerStyle='col-lg-4 mt-3 mt-md-0'
              value={selected.singleItems}
              fullValue={selected.singleItemsFullValue}
            />
          </div>
        </div>
      </div>
      {filters && !!filters.length && (
        <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center'>
          <div className='mr-2'>
            <strong>Filters:</strong>
          </div>
          {filters}
          <div className='filter-chips-delete-all' onClick={resetFilters}>
            <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
          </div>
        </div>
      )}
    </>
  );
};
