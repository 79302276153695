import Validators from 'library/utilities/Validators';

export const exportKitModel = ({ categoriesAndSystems, conditions, countries, kitStatus, hasMaxNumberOfStocks }) => {
  const hasValidForStocks = hasMaxNumberOfStocks ? [{ check: Validators.maxLength, message: 'maxLengthErrorMessage', strLength: 20 }] : [];

  return [
    {
      label: 'stockMaxThree',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'stock',
      validators: [
        { check: Validators.required, message: 'requiredErrorMessage' },
        ...hasValidForStocks,
      ],
      required: true,
      styleClass: 'col-12',
      options: countries,
      hasSection: true,
      multiSelect: true,
      filter: true,
      idKey: 'id',
      displayValue: [{ key: 'locationName', separator: '' }],
      optionsArrayKey: 'stockInformation',
      titleDisplay: [
        { key: 'shortName', separator: ' ' },
        { key: 'name', separator: '' },
      ],
    },
    {
      label: 'categorySystem',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'category',
      validators: [],
      required: false,
      styleClass: 'col-12',
      options: categoriesAndSystems,
      hasSection: true,
      multiSelect: true,
      filter: true,
      selectAllOption: true,
      idKey: 'systemMainComponentId',
      displayValue: [{ key: 'systemMainComponentName', separator: '' }],
      optionsArrayKey: 'systemMainComponent',
      titleDisplay: [{ key: 'systemClassName', separator: '' }]
    },
    {
      label: 'status',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'status',
      validators: [],
      required: false,
      styleClass: 'col-12',
      options: kitStatus,
      hasSection: false,
      multiSelect: true,
      filter: false,
      selectAllOption: true,
      idKey: 'kitStatusId',
      displayValue: [{ key: 'translatedStatus', separator: '' }],
    },
    {
      label: 'conditionText',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'condition',
      validators: [],
      required: false,
      styleClass: 'col-12',
      options: conditions,
      hasSection: false,
      multiSelect: true,
      filter: true,
      selectAllOption: true,
      idKey: 'kitStatusId',
      displayValue: [{ key: 'translatedStatus', separator: '' }],
    },
  ]
};
