import React from 'react';

import strings from 'resources/locales/Translate';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import { llManagementBreadcrumbData } from './LLManagement.constants';
import CalculationMethodTab from './Components/CalculationMethodTab';
import AccountStatementTab from './Components/AccountStatementTab';
import KPIsTab from './Components/KPIsTab';
import ControllingReportTab from './Components/ControllingReportTab';
import SystemClassificationTab from './Components/SystemClassificationTab';

const LLManagement = ({ appActions, user }) => {
  
  let tabs = [
    {
      id: 1,
      tabTitle: 'accountStatement',
      className: '',
      render: () => {
        return (
          <AccountStatementTab />
        );
      },
    },
  ];
  if (user.admin) {
    tabs = [
      {
        id: 1,
        tabTitle: 'systemClassification',
        className: '',
        render: () => {
          return (
            <SystemClassificationTab/>
          );
        },
      },
      {
        id: 2,
        tabTitle: 'calculationMethod',
        className: '',
        render: () => {
          return (
            <CalculationMethodTab />
          );
        },
      },
      {
        id: 3,
        tabTitle: 'accountStatement',
        className: '',
        render: () => {
          return (
            <AccountStatementTab />
          );
        },
      },
      {
        id: 4,
        tabTitle: 'controllingReport',
        className: '',
        render: () => {
          return (
           <ControllingReportTab/>
          );
        },
      },
      {
        id: 5,
        tabTitle: 'kpis',
        className: '',
        render: () => {
          return (
            <KPIsTab />
          );
        },
      }
    ];
  }
 
  const { displayActionMessage, type, message, showAsIs } = appActions;
  return (
    <div className='mb-3'>
      {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : (strings[message] || message)} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={llManagementBreadcrumbData} />
        <h1 className='kit-title'>{strings.labsLocationManagement}</h1>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default LLManagement;
