import React, { useState, useEffect } from 'react';
import { ReparationSlotsCancelPopup } from './ReparationSlotsCancelPopup.component';
import Table from 'library/common/commonComponents/Table/Table';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import './reparationslots.styles.scss';
import { dataTable } from './dataTable';
import * as $ from 'jquery';

const ReparationSlots = (props) => {
  const { language, kitDetail } = props;
  const isEditingKit = props.history && props.history.location && props.history.location.pathname && props.history.location.pathname.includes('edit-kit');
  const [reparationSlots, setReparationSlots] = useState(kitDetail.kitReparation);
  const [page, setPage] = useState(0);
  const [isLoading,setIsLoading]= useState(false);
  const totalPages = reparationSlots && Math.ceil(reparationSlots.length / 10);
  const [reparationIdtoDelete, setReparationIdtoDelete] = useState('');

  useEffect(() => {
    if (kitDetail.kitInformationId) {
        const slots = kitDetail &&  kitDetail.kitReparation && kitDetail.kitReparation.length >0 ? kitDetail.kitReparation :null;
        setReparationSlots(slots);
    }
  }, [page, kitDetail]);

  const onContinueBooking = (id) =>{
    setIsLoading(true);
    props.deletereparationSlotForKit(reparationIdtoDelete, reparationSlots, setReparationSlots);
    setIsLoading(false);
    $(`#${id}`).modal('hide');
  }

  const onCancelClick = (id) =>{
    $(`#${id}`).modal('hide');
  }

  const cancelSemZmccLoanData = () => {
    $('#delete-reparation-slot-modal').modal('show');
  }

  const deleteKitReparation = (kitReaparationId) =>{
    setReparationIdtoDelete(kitReaparationId);
    $('#delete-reparation-slot-modal').modal('show');
  }

  const handlePageChange = page => setPage(page);

  return (
    <>
      <div>
        {reparationSlots && reparationSlots.length > 0 ? (
          <>
            <div className='container-fluid m-3 col-sm-6'>
              <Table
                cols={dataTable(language, isEditingKit, kitDetail.kitInformationId, deleteKitReparation, reparationSlots, setReparationSlots)}
                rows={reparationSlots}
              />
            </div>
            <div className='col d-flex justify-content-end'>
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <strong className='d-flex justify-content-center dummy-data mt-3'>No Reparation Slots</strong>
        )}
      </div>
      <ReparationSlotsCancelPopup
          id ='delete-reparation-slot-modal'
          onCancelClick={() => onCancelClick('delete-reparation-slot-modal')}
          onContinueBooking={() => onContinueBooking('delete-reparation-slot-modal')}
          isLoading={isLoading}
       />
      </>
  );
};

export default ReparationSlots;
